class InPageDropdownNav extends HTMLElement {
  constructor() {
    super();

    this.dropDownNav = this.querySelector('.inpage-dropdown-nav');

    this.dropDownNav.addEventListener('change', this._movePage.bind(this));
  }

  _movePage(event) {
    // Get anchor link url
    let currentDropdownValue = event.target.value;

    // Set 'location' to URL
    location = currentDropdownValue;
  }
}

window.customElements.define('cla-inpagedropdownnav', InPageDropdownNav);
