//
// Homepage animation functions
//
import 'https://flackr.github.io/scroll-timeline/dist/scroll-timeline.js';

export default class HomepageAnimations {
  constructor() {

    const homepageBodyElement = document.querySelector(".page--home");

    // Initialise Scroll Nav Animation JS if present, else quit out now
    if (!homepageBodyElement) return;

    // Get main nav height
    // TODO: Recalculate this on smaller screen with MatchMedia?
    let mainNavHeightVar = getComputedStyle(document.body).getPropertyValue('--main-nav-height');
    let mainNavHeightValue = parseInt(mainNavHeightVar);

    // Get elements we are going to animate
    const homepageArcElement = document.getElementById('js-home-background-arc');
    const homepageArcInnerElement = document.getElementById('js-home-background-arc-inner');

    // Create new timeline
    const tl = new ScrollTimeline({
      source: document.documentElement,
    });

    // Move the arc to the top of the page
    homepageArcElement.animate(
      { 'insetBlockStart': '0px'},
      { fill: 'both',
        timeline: tl,
        rangeStart: '0px',
        rangeEnd: mainNavHeightValue + 'px',
      });

    // Expand the clip path outwards
    homepageArcInnerElement.animate(
      { 'clipPath': 'circle(112.5% at 50% 100%)'},
      { fill: 'both',
        timeline: tl,
        rangeStart: mainNavHeightValue + 'px',
        rangeEnd: (mainNavHeightValue * 2) + 'px',
      });

  }

}

new HomepageAnimations();
