//
// Pinned section behaviour
//
export default class PinnedSectionHandler {
  constructor() {

    this.pinnedSections = document.querySelectorAll(".pinned-section");

    // Initialise Pinned section JS if present, else quit out now
    if (!this.pinnedSections) return;

    // Get viewport height
    this.viewPortHeight = window.innerHeight;

    this.init();
  }

  init() {
    this.pinnedSections.forEach(pinnedSection => {
      this.pinSection(pinnedSection)
    });
  }

  pinSection(pinnedSection) {
    let pinnedSectionHeight = pinnedSection.clientHeight;

    // If the height of the section is the same or less than the viewport height,
    // then enable position sticky
    if (pinnedSectionHeight <= this.viewPortHeight) {
      pinnedSection.classList.add('pinned-section--enabled');
    }
  }

}

// Run the handler
document.addEventListener('DOMContentLoaded', function () {
  new PinnedSectionHandler();
}, false);
