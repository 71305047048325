class MobileNavTrigger extends HTMLElement {
  constructor() {
    super();

    this.button = this.querySelector('button');
    this.button.addEventListener('click', this._toggle);
  }

  _toggle = () => {
    const expanded = eval(this.button.getAttribute('aria-expanded'));
    const controlled = document.getElementById(this.button.getAttribute('aria-controls'));

    this.button.setAttribute('aria-expanded', !expanded);

    this.button.classList.toggle('site-header__mb-nav-trigger--open');
    controlled.classList.toggle('mobile-nav-container--open');
    document.body.classList.toggle('body--mobile-menu-open');
  }
}

window.customElements.define('cla-mobilenavtrigger', MobileNavTrigger);


class QuickNav extends HTMLElement {
  constructor() {
    super();

    this.body = document.getElementsByTagName('body')[0];
    this.lastScrollTop = window.scrollY || document.documentElement.scrollTop;
    window.addEventListener('scroll', this.handleScroll.bind(this), false);
  }
  handleScroll() {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    // get nav height
    const style = getComputedStyle(this.body)
    const navHeight = parseInt(style.getPropertyValue('--main-nav-height'));

    //check is nav is scrolled past
    if (scrollTop > navHeight) {
      // apply quicknav styles if scrolled past
      this.body.classList.add('body--quicknav');
      // delay transitions happening so nav doesn't reappear
      setTimeout(() => {
        this.body.classList.add('body--quicknav-transition');
      }, 100);

      if (scrollTop > this.lastScrollTop) {
        // if down scrolling hide nav
        this.body.classList.remove('body--quicknav-visible');
      } else {
        // if up scrolling show nav
        this.body.classList.add('body--quicknav-visible');
      }

    } else {
      // remove quicknav styles when scrolling back to top
      this.body.classList.remove('body--quicknav');
      this.body.classList.remove('body--quicknav-visible');
      // remove transitions class once animation finishes
      setTimeout(() => {
        this.body.classList.remove('body--quicknav-transition');
      }, 550);
    }
    this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
  }

}

window.customElements.define('cla-quicknav', QuickNav);
