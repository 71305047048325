//
// Animation Handler
//
export default class AnimationHandler {
  constructor() {

    function scrollTrigger(selector, options = {}) {
      let els = document.querySelectorAll(selector)
      els = Array.from(els)
      els.forEach(el => {
        // Passing the options object to the addObserver function
        addObserver(el, options)
      })
    }

    function addObserver(el, options) {
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if(entry.isIntersecting) {
            // Extract the classes to add from the 'data-on-visible' attribute
            const newClassNames = entry.target.getAttribute('data-on-visible');
            newClassNames.split(' ').forEach(className => {
              entry.target.classList.add(className);
            });
            observer.unobserve(entry.target)
          }
        })
      }, options) // Passing the options object to the observer
      observer.observe(el)
    }

    // Refactor this to be used globally?
    scrollTrigger('[data-on-visible]', {
      rootMargin: '0px',
      threshold: 0
    });

  }
}

new AnimationHandler();
