import "@src/scss/global.scss";

// Require all Twig component styles
// XXX: Can't use aliases in import.meta.glob
import.meta.glob("../../craft/components/**/*.scss", { eager: true });

// Require all Twig component custom element scripts
import.meta.glob("../../craft/components/**/elements.js", { eager: true });

// Check if JS is enabled in browser, if so the `no-js` class is removed from <body>
document.body.classList.remove('no-js');

document.addEventListener('DOMContentLoaded', function () {
  document.body.classList.remove('preload');
}, false);

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
