class InPagePillNav extends HTMLElement {
  constructor() {
    super();

    this.pillNav = this.querySelector('.inpage-pill-nav');
    this.pillElementCount = this.pillNav.style.getPropertyValue('--inpage-pill-nav-count');
    this.pillNav.style.setProperty('--inpage-pill-nav-slider-offset', `0px`);
    this.pillNavElements = this.querySelectorAll('.inpage-pill-nav__pill');
    this.activePillElement = this.querySelector('.inpage-pill-nav__pill--active');
    let activePillBounding = this.activePillElement.getBoundingClientRect();
    this.pillNav.style.setProperty('--inpage-pill-nav-slider-width', `${activePillBounding.width}px`);

    this.pillNavElements.forEach(pillNavElement => {
      pillNavElement.addEventListener('mouseover', this._moveSlider.bind(this));
      pillNavElement.addEventListener('focus', this._moveSlider.bind(this));
      pillNavElement.addEventListener('click', this._activePillLink.bind(this));
    });
  }

  _moveSlider(event) {
    this._clearActivePillClasses();

    let currentPillNavElement = event.target;

    // Get bounding rects of parent and active element
    let parentBoundingRect = this.pillNav.getBoundingClientRect();
    let activeBoundingRect = currentPillNavElement.getBoundingClientRect();
    // Calculate offset and width values
    let sliderOffset = activeBoundingRect.x - parentBoundingRect.x;
    let sliderMarkerWidth =  activeBoundingRect.width;
    // Update offset and width CSS variable
    this.pillNav.style.setProperty('--inpage-pill-nav-slider-offset', `${sliderOffset}px`);
    this.pillNav.style.setProperty('--inpage-pill-nav-slider-width', `${sliderMarkerWidth}px`);
    // this.pillNav.style.setProperty('--inpage-pill-nav-slider-width', );
    // Add active class
    currentPillNavElement.classList.add('inpage-pill-nav__pill--active');
  }

  _activePillLink(event) {
    this._clearActivePillClasses();

    let currentPillNavElement = event.target;

    currentPillNavElement.classList.add('inpage-pill-nav__pill--active');
  }

  // Remove all active classes
  _clearActivePillClasses() {
    this.pillNavElements.forEach(pillNavElement => {
      pillNavElement.classList.remove('inpage-pill-nav__pill--active');
    });
  }
}

window.customElements.define('cla-inpagepillnav', InPagePillNav);
